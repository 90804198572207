<template>
  <v-container>
    <div class="d-flex flex-row flex-grow-1">
      <PageTitle text="Deliveries" class="ft d-flex flex-row" />
      <div>
        <v-text-field
          dense
          append-icon="search"
          light
          single-line
          :disabled="isListLoading"
          v-model="search"
          style="min-width: 400px;"
          solo-inverted
          placeholder="Search by order reference or branch ..."
          class="mt-2 ft font-size-sm black--text"
          hide-details
          flat
        />
      </div>
    </div>
    <div class="d-flex flex-row flex-grow-1 justify-space-between py-3">
      <h4 class="blue-grey--text mb-4 ml-1 ft font-size-md">
        List of deliveries
      </h4>
      <v-btn
        :loading="isListLoading"
        @click="
          $store.dispatch('deliveries/list', {
            page: options.page,
            itemsPerPage: options.itemsPerPage
          })
        "
        icon
        color="primary"
      >
        <i class="material-icons-outlined">refresh</i>
      </v-btn>
    </div>
    <div class="d-flex flex-column flex-grow-1">
      <v-data-table
        :loading="isListLoading"
        class="ft box-shadow-light font-weight-medium"
        :items="filteredDeliveries"
        item-key="orderReference"
        sort-by="name"
        :search.sync="search"
        group-by="orderLineItemId.orderReference"
        :server-items-length="pagination.total"
        :items-per-page="pagination.limit"
        :footer-props="{
          itemsPerPageOptions: [10, 20, 30]
        }"
        :options.sync="options"
        :headers="headers"
      >
        <template #item.date="{item}">
          <span class="ft font-weight-medium">
            {{ item.date | timestamp }}</span
          >
        </template>
        <template #item.productId="{item}">
          <span class="ft font-weight-medium"> {{ item.productId.name }}</span>
        </template>
        <template #item.orderReference="{item}">
          <v-chip
            label
            :color="'#9efb9e66'"
            class="ft font-weight-medium mr-2 text-uppercase"
            small
          >
            <span
              :style="{
                color: 'rgba(3, 91, 3, 0.83)'
              }"
              class="font-weight-bold"
              >{{ item.orderLineItemId.orderReference }}</span
            >
          </v-chip>
        </template>
        <template #item.isOrderCompleted="{item}">
          <v-chip
            label
            :color="'#9efb9e66'"
            class="ft font-weight-medium mr-2 text-uppercase"
            small
          >
            <span
              :style="{
                color: 'rgba(3, 91, 3, 0.83)'
              }"
              class="font-weight-bold"
              >{{ item.orderLineItemId.isOrderCompleted }}</span
            >
          </v-chip>
        </template>
        <template #item.branchId="{item}">
          <span class="ft font-weight-bold">{{ item.branchId.name }}</span>
        </template>

        <template #item.quantity="{item}">
          <span class="ft font-weight-bold">{{ item.quantity }} (Litres)</span>
        </template>
        <template #item.isDeliveryAccepted="{item}">
          <v-chip
            label
            :color="
              item.isDeliveryAccepted ? '#9efb9e66' : 'rgb(247, 189, 172)'
            "
            class="ft font-weight-medium mr-2 text-uppercase"
            small
          >
            <span
              :style="{
                color: item.isDeliveryAccepted
                  ? 'rgba(3, 91, 3, 0.83)'
                  : 'rgb(238, 78, 0)'
              }"
              class="font-weight-bold"
              >{{ item.isDeliveryAccepted ? "Accepted" : "Pending" }}</span
            >
          </v-chip>
        </template>
        <template #item.actions="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                @click="open('edit', 'deliveries/details', item.id)"
                v-on="on"
                small
                :disabled="item.isDeliveryAccepted"
                fab
                icon
              >
                <i class="material-icons-outlined font-size-md"
                  >model_training</i
                >
              </v-btn>
            </template>
            <span class="  ft font-weight-medium">Settle Disparities</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn disabled icon fab small color="primary" v-on="on">
                <i class="material-icons-outlined font-size-sm">
                  local_shipping
                </i>
              </v-btn>
            </template>
            <span class=" font font-weight-medium text-sm">View Order</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <EditDeliveryQuantity
        :state="dialog('edit')"
        @actions="close"
        :details="details"
        :loading="loading"
        @editQuantity="editQuantity"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../../components/PageTitle";
import { timestamp } from "../../../filters/timestamp";
import DialogMixins from "../../../mixins/DialogMixins";

export default {
  name: "Deliveries",
  components: {
    PageTitle,
    EditDeliveryQuantity: () => import(`./dialogs/EditDeliveryQuantity`)
  },
  filters: {
    timestamp
  },
  mixins: [DialogMixins],
  data() {
    return {
      totalDesserts: 0,
      desserts: [],
      search: "",

      headers: [
        {
          text: "Date",
          value: "date",
          sortable: true,
          groupable: false
        },
        {
          text: "Ref.",
          value: "orderReference",
          sortable: true
        },
        {
          text: "Product",
          value: "productId"
        },
        {
          text: "Branch",
          value: "branchId"
        },
        {
          text: "Quantity",
          value: "quantity"
        },

        {
          text: "Confirmed Status",
          value: "isDeliveryAccepted"
        },
        {
          text: "Actions",
          value: "actions"
        }
      ],
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10
      }
    };
  },
  computed: {
    ...mapGetters({
      isListLoading: "getIsListLoading",
      loading: "getIsLoading",
      deliveries: "deliveries/getAllDeliveries",
      details: "deliveries/getDeliveryDetails",
      pagination: "deliveries/getPagination"
    }),
    filteredDeliveries() {
      return this.deliveries.filter(delivery => {
        return (
          delivery.orderLineItemId.orderReference.indexOf(this.search) !== -1 ||
          delivery.branchId.name
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -``
        );
      });
    }
  },
  watch: {
    options: {
      handler(payload) {
        this.$store.dispatch("deliveries/list", {
          page: payload.page,
          itemsPerPage: payload.itemsPerPage
        });
      },
      deep: true
    }
  },
  methods: {
    editQuantity(payload) {
      this.$store.dispatch("deliveries/settleDisparities", payload);
    }
  },
  created() {
    this.$store.dispatch("deliveries/list", {
      page: this.options.page,
      itemsPerPage: this.options.itemsPerPage
    });
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
