var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('PageTitle',{staticClass:"ft d-flex flex-row",attrs:{"text":"Deliveries"}}),_c('div',[_c('v-text-field',{staticClass:"mt-2 ft font-size-sm black--text",staticStyle:{"min-width":"400px"},attrs:{"dense":"","append-icon":"search","light":"","single-line":"","disabled":_vm.isListLoading,"solo-inverted":"","placeholder":"Search by order reference or branch ...","hide-details":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between py-3"},[_c('h4',{staticClass:"blue-grey--text mb-4 ml-1 ft font-size-md"},[_vm._v(" List of deliveries ")]),_c('v-btn',{attrs:{"loading":_vm.isListLoading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch('deliveries/list', {
          page: _vm.options.page,
          itemsPerPage: _vm.options.itemsPerPage
        })}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-data-table',{staticClass:"ft box-shadow-light font-weight-medium",attrs:{"loading":_vm.isListLoading,"items":_vm.filteredDeliveries,"item-key":"orderReference","sort-by":"name","search":_vm.search,"group-by":"orderLineItemId.orderReference","server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.limit,"footer-props":{
        itemsPerPageOptions: [10, 20, 30]
      },"options":_vm.options,"headers":_vm.headers},on:{"update:search":function($event){_vm.search=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("timestamp")(item.date)))])]}},{key:"item.productId",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium"},[_vm._v(" "+_vm._s(item.productId.name))])]}},{key:"item.orderReference",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":'#9efb9e66',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
              color: 'rgba(3, 91, 3, 0.83)'
            })},[_vm._v(_vm._s(item.orderLineItemId.orderReference))])])]}},{key:"item.isOrderCompleted",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":'#9efb9e66',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
              color: 'rgba(3, 91, 3, 0.83)'
            })},[_vm._v(_vm._s(item.orderLineItemId.isOrderCompleted))])])]}},{key:"item.branchId",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-bold"},[_vm._v(_vm._s(item.branchId.name))])]}},{key:"item.quantity",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-bold"},[_vm._v(_vm._s(item.quantity)+" (Litres)")])]}},{key:"item.isDeliveryAccepted",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isDeliveryAccepted ? '#9efb9e66' : 'rgb(247, 189, 172)',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
              color: item.isDeliveryAccepted
                ? 'rgba(3, 91, 3, 0.83)'
                : 'rgb(238, 78, 0)'
            })},[_vm._v(_vm._s(item.isDeliveryAccepted ? "Accepted" : "Pending"))])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","disabled":item.isDeliveryAccepted,"fab":"","icon":""},on:{"click":function($event){return _vm.open('edit', 'deliveries/details', item.id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md"},[_vm._v("model_training")])])]}}],null,true)},[_c('span',{staticClass:"  ft font-weight-medium"},[_vm._v("Settle Disparities")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":"","icon":"","fab":"","small":"","color":"primary"}},on),[_c('i',{staticClass:"material-icons-outlined font-size-sm"},[_vm._v(" local_shipping ")])])]}}],null,true)},[_c('span',{staticClass:" font font-weight-medium text-sm"},[_vm._v("View Order")])])]}}])}),_c('EditDeliveryQuantity',{attrs:{"state":_vm.dialog('edit'),"details":_vm.details,"loading":_vm.loading},on:{"actions":_vm.close,"editQuantity":_vm.editQuantity}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }